import {Fancybox} from '@fancyapps/ui'
import '@/fonts/RionaSans-Black.woff'
import '@/fonts/RionaSans-Bold.woff'
import '@/fonts/RionaSans-Light.woff'
import '@/fonts/RionaSans-Medium.woff'
import '@/fonts/RionaSans-Regular.woff'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import 'animate.css'
import '@css/app.pcss'

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
// import function to register Swiper custom elements
import {register} from 'swiper/element/bundle'

window.Alpine = Alpine

Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.start()

// register Swiper custom elements
register()

Fancybox.bind('[data-fancybox]', {
  compact: true,
  height: 200,
  dragToClose: false,
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
